.section-intro {
	position: relative;
	margin: 3.5rem 0;

	.container {
		min-height: calc(100vh - 10rem);
		padding-top: 4rem;
		padding-bottom: 4rem;
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
		&.intro-home {
		position: relative;
		margin: 0;
		display: flex;
		align-items: center;
		background-image: url(../img/home.jpg);
		background-size: cover;
		background-position: 100% 50%;
		.advantages {
			margin-top: auto;
			margin-bottom: -4rem;
		}
	}
	&.intro-catalog {
		position: relative;
		margin: 0;
		.container {
		min-height: 40rem;
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
		&:after {
			content: "";
			display: block;
			width: 50%;
			aspect-ratio: 1/1;
			border-radius: 100%;
			background: #f1f3f7;
			position: absolute;
			bottom: -10rem;
			right: -10%;
			z-index: 1;

			@media (max-width: 992px) {
				display: none;
			}
		}
	}
		&.intro-tax {
		position: relative;
		margin: 0;
		display: flex;
		align-items: center;
		background-size: cover;
		background-position: 100% 50%;
	.container {
		min-height: 1rem;
	}
	}
}
.intro {
	flex-grow: 1;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	.block {
		max-width: 40rem;
		.text {
			font-size: 1rem;
			margin-top: 1.5rem;
			.widget-title{
				color: $dop-color;
				margin-bottom: 1.5rem;
			}
		}
		.buttons {
			width: 100%;
			margin-top: 3rem;
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem 1rem;
			@media (max-width: 992px) {
				margin-top: 1.5rem;
			}
		}
		.button {
		}
	}
	.calc{
		width: 30rem;
		padding: 2rem;
		    background: #f1f3f7;
	}
}
