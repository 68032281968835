.page-content {
}

.container {
}

.post-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.25rem;

	img {
		max-width: 35.1875rem;
	}
	.title {
		margin-top: 1.25rem;
		font-size: 1.875rem;
		font-family: Montserrat-Bold, sans-serif;
		font-weight: 700;
	}
	.button {
		background: $global-color;
		color: #fff;
	}
}
