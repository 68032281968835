.button {
	min-width: 10rem;
	height: 3rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: "Montserrat-Bold";
	font-size: 1rem;
	line-height: 1;
	text-transform: uppercase;
	border-radius: 0.25rem;
	box-shadow:
		0.375rem 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1),
		inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.1);
	border: 1px solid transparent;
	padding: 0.75rem 1.5rem;
	gap: 0.625rem;
	position: relative;
	cursor: pointer;
	@media (max-width: 992px) {
		font-size: 1rem;
		height: 3.5rem;
	}

	&-global {
		color: #fff;
		background-color: $global-color;
		&:hover,
		&:active,
		&:focus {
			color: #fff;
			background-color: $dop-color;
			border-color: $dop-color;
			box-shadow:
				0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2),
				inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.05);
		}

		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	&-white {
		&:hover,
		&:active,
		&:focus {
			box-shadow:
				0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2),
				inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.03);
		}

		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}
}
