.section-advantages {
	position: relative;
	z-index: 10;
}

.advantages {
	display: flex;
	gap: 1.25rem;
	width: 100%;
	border: 0.25rem solid rgb(236, 236, 236);
	border-radius: 0.4375rem;
	background: rgb(255, 255, 255);
	padding: 2.5rem 1.25rem;
	@media (max-width: 992px) {
		flex-wrap: wrap;
	}
	.item {
		background: #ffffff;
		width: calc(33.33% - 0.9375rem);
		display: flex;
		gap: 1.25rem;
		padding: 0 1.25rem;
		border-right: 0.125rem solid rgb(174, 174, 174);
		&:last-child {
			border: none;
		}
		@media (max-width: 992px) {
			width: 100%;
			border: none;
		}
	}

	.image {
		width: 4.0625rem;
		height: 4.0625rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.375rem;
		background-color: $dop-color;
		box-shadow:
			0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2),
			inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.05);
		img {
			width: 2rem;
			height: auto;
		}
	}

	.content {
		width: calc(100% - 5.3125rem);
		display: flex;
		flex-direction: column;
		gap: 0.625rem;
		font-size: 0.875rem;
		@media (max-width: 992px) {
			font-size: 1.1rem;
		}
	}

	.name {
		font-family: "Montserrat-Bold";
	}
}
