
.swiper-button-next,
.swiper-button-prev {
	position: absolute;
	top: 50%;
	width: 2.75rem;
	height: 2.75rem;
	margin-top: -1.5625rem;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #252b3b;
	background: #ffffff;
	border-radius: 0.625rem;
	border: none;
	font-size: 0.75rem;
	@media (max-width: 640px) {
		width: 2.5rem;
		height: 2.5rem;
		font-size: 0.6rem;
	}
	
	&:after {
		font-size: 1rem;
		@media (max-width: 640px) {
			font-size: 0.8rem;
		}
	}
	&:hover {
		background: $global-color;
		color: #ffffff;
		box-shadow: 0 0.8125rem 1.25rem rgba(121, 183, 69, 0.36);
	}
}
.swiper-button-next {
	right: -5rem;
	@media (max-width: 1300px) {
		right: -3rem;
	}
	@media (max-width: 1140px) {
		right: -1.5rem;
	}
	@media (max-width: 414px) {
		right: -0.5rem;
	}
}
.swiper-button-prev {
	left: -5rem;
	@media (max-width: 1300px) {
		left: -3rem;
	}
	@media (max-width: 1140px) {
		left: -1.5rem;
	}
	@media (max-width: 414px) {
		left: -0.5rem;
	}
}
.swiper-button-disabled {
	opacity: 0;
	cursor: auto;
	pointer-events: none;
}
.swiper-pagination {
	display: none;
	@media (max-width: 992px) {
		display: block;
		bottom: 0 !important;
	}
}
.swiper-pagination-bullet-active {
	background: #2c3345;
}
