.brand-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 1.25rem;
	padding: 2rem;
	border: 0.25rem solid rgb(236, 236, 236);
	border-radius: 0.5rem;
	background: rgb(255, 255, 255);
	margin: 4rem 0;
	@media (max-width: 680px) {
		margin-top: 3rem;
		justify-content: space-between;
	}
	.item {
		width: calc(25% - 1.25rem);
		display: flex;
		align-items: center;
		gap: 0.5rem;
		font-family: "Montserrat-Medium";
		font-size: 1rem;
		color: #111111;
		@media (max-width: 992px) {
			width: calc(33.33% - 1rem);
		}
		@media (max-width: 680px) {
			width: calc(50% - 1rem);
		}
		&:hover {
			color: $dop-color;
		}
	}

	.count {
		font-size: 0.875rem;
		color: #a2a5a8;
	}

	.more {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		font-size: 0.875rem;
		line-height: 1;
		color: $global-color;
		cursor: pointer;
		margin-top: 0.625rem;
		&:hover {
			color: $dop-color;
		}
		img,
		svg {
			width: 0.5rem;
			height: auto;
			transform: rotate(90deg);
		}
	}
}
