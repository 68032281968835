.post{
	width: calc(33.33% - 1.25rem);
	.image{
		width: 100%;
		aspect-ratio: 380/250;
		display: block;
		margin-bottom: 1.25rem;
		border-radius: 0.5rem;
		background-repeat: no-repeat;
	}
	.post-date{
		margin-top: 0.9375rem;
		margin-bottom: 0.3125rem;
	}
	.widget-title{
		font-size: 1rem;
	}
	@media screen and (max-width: 768px) {
		width: calc(50% - 1.25rem);
	}
	@media screen and (max-width: 480px) {
		width: 100%;
	}
}