.brand-list {
    padding: 1.875rem;
    columns: 4;
    column-gap: 1.25rem;
    column-width: 16.875rem;
    border: 1px solid #EDEEF0;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        column-gap: 0.62rem;
        column-width: 11rem;
        padding: 1.5rem;

    }
}
.brand-item+.brand-item {
    margin-top: 1.25rem;
}

.brand-item {
    display: flex;
    gap: 0.3125rem;
    color: #111111;
}

.count {
    font-size: 0.875rem;
    color: #A2A5A8;
}