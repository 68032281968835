// .cars {
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1.875rem;
// }

.section-cars {
	.products {
		margin-top: 3.75rem;
	}

	.cars-filter {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap:1.5rem ;
		margin-top: 3rem;
		a {
			font-family: "Montserrat-Bold";
			text-transform: uppercase;
			color: $global-color;
			font-size: 0.95rem;
			&:hover {
				color: $dop-color;
				text-decoration: underline;
			}
		}
	}
}
