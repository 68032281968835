/*------ modal ------*/

.state {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&.active {
		visibility: visible;
		opacity: 1;
		z-index: 99999;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(0.625rem);
	}
}

.state-box {
	width: 31.25rem;
	padding: 1.875rem;
	border: 0.25rem solid rgb(236, 236, 236);
	border-radius: 0.4375rem;
	max-width: 90%;
	max-height: 90%;
	height: auto;
	overflow-y: auto;
	background: #fff;

	position: relative;
	display: flex;

	@media (max-width: 540px) {
		width: 30rem;
	}

	.close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 1rem;
		height: 1rem;
		cursor: pointer;

		@media (max-width: 30rem) {
			top: 1.5rem;
			right: 1rem;
		}

		&:before,
		&:after {
			content: "";
			display: block;
			width: 1rem;
			height: 0.125rem;
			background: #6d6d6d;
			position: absolute;
			transition: 0.6s;
			top: 0.3rem;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}

		&:hover {
			&:before,
			&:after {
				background-color: $global-color;
			}
		}
	}

	.sectiom-form {
		margin: 0;
		width: 100%;
	}

	.widget-title {
		font-family: "Montserrat-Bold";
		font-size: 1.5rem;
		margin-bottom: 1.25rem;
	}

	// radio box
	.visually-hidden {
		position: absolute !important;
		width: 1px !important;
		height: 1px !important;
		margin: -1px !important;
		border: 0 !important;
		padding: 0 !important;
		white-space: nowrap !important;
		clip-path: inset(100%) !important;
		clip: rect(0 0 0 0) !important;
		overflow: hidden !important;
	}

	.radiobox {
		display: flex;
		gap: 0.9375rem;
		width: 100%;
	}

	.country:has(.custom-radio:checked) {
		color: #08b72e;
		border: 0.0625rem solid #08b72e;
		border-radius: 0.625rem;
		background: rgba(8, 183, 46, 0.1);
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: 0.9375rem;

		.text {
			margin-top: 0.3125rem;
			font-size: 1rem;
			font-family: "Montserrat-Medium";
		}

		.text-enter {
			padding: 0.75rem 1.25rem;
			border-radius: 0.625rem;
			color: #767980;
			background: #ffffff;
			border: 0.0625rem solid #e0e1e7;
		}

		.line {
			margin-top: 0.3125rem;
			width: 100%;
			border: 0.0625rem solid #edeef0;
		}
		.button{
			font-size: 1rem;
		}
	}

	.submit-btns {
		display: flex;
		gap: 1.25rem;
	}

	.button[type="submit"] {
		flex: 1;
		color: #ffffff;

		&.whatsapp-bg {
			background: $global-color;
		}

		&.call-bg {
			background: $dop-color;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	.country {
		flex: 1;
		text-transform: uppercase;
		border-radius: 0.625rem;
		color: #313335;
		background: #fbfbfb;
		border: 1px solid #e0e1e7;
		font-size: 0.875rem;
	}
	.inscription {
		font-size: 0.875rem;
	}
}

.to-state {
	cursor: pointer;
}

.state[data-state="call-to-form3"] {
	.state-box {
		width: 68.75rem;

		.block {
			display: flex;
			gap: 1.875rem;
			@media screen and (max-width: 650px) {
				flex-direction: column;
			}
		}

		@media screen and (max-width: 992px) {
			width: auto;
			max-width: 68.75rem;
			margin-inline: 1rem;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			padding-block: 1.25rem;
		}
		@media screen and (max-width: 650px) {
			padding-top: 1.875rem;
		}
	}

	.total-cost {
		background: #f6f6f6;
		border-radius: 0.625rem;
		color: $font-color;
		max-width: 22.5rem;
		padding: 1.25rem;
		display: flex;
		flex-direction: column;
		gap: 1.25rem;

		.title {
			font-size: 1.5rem;
			font-family: "Montserrat-Bold";
			color: $font-color-sub;
		}

		.total-cost-inner {
      display: none;
      &.active{
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      }

			.text {
				font-family: "Montserrat-Bold";
				font-size: 0.875rem;
			}

			.total-count {
				font-family: "Montserrat-Bold";
				font-size: 1.5rem;
				color: $dop-color;
			}
		}

		.course {
			font-size: 1rem;
			display: flex;
			flex-direction: column;
			gap: 0.625rem;

			.text {
				font-family: "Montserrat-Bold";
				font-weight: 700;
			}
		}

		.course-items {
			font-size: 0.875rem;
			display: flex;
			gap: 1.875rem;
		}

		.course-item {
			display: flex;
			flex-direction: column;
			gap: 0.3125rem;
		}

		.course-count {
			font-family: "Montserrat-Bold";
			white-space: nowrap;
		}

		.course-currency {
			color: $font-color-light;
		}
		@media screen and (max-width: 650px) {
			max-width: 100%;
		}
	}

	.calculation {
	  flex-grow: 1;

		color: $font-color-sub;
		font-size: 1rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
      .check-nds{
        margin-left: auto;
      }
		.widget-title {
			font-size: 1.25rem;
			margin: 0;

			span {
				font-family: "Montserrat-Medium";
				font-size: 1.125rem;
				color: $font-color-light;
				width: 100%;
				display: block;
			}
		}

		.calc-items {
      display: none;
      &.active{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
			
		}

		.calc-item {
			display: flex;
			gap: 1.25rem;
			justify-content: space-between;
		}

		.text {
		}

		.cost {
			display: flex;
			gap: 0.9375rem;
			font-family: Montserrat-Bold, sans-serif;
			font-size: 1rem;
			font-weight: 700;

			span {
				font-family: Montserrat-Regular, sans-serif;
				font-weight: 400;
				color: $font-color-light;
			}
			@media screen and (max-width: 43.75rem) {
				flex-direction: column-reverse;
				align-items: end;
				white-space: nowrap;
			}
		}

		.form-wrapper {
			display: flex;
			flex-direction: column;
			gap: 0.625rem;

			.annotation {
				color: #515456;
			}
		}
	}
	.form {
		flex-direction: column;
		margin-bottom: 1.5rem;

		.form-item {
			width: 100%;

			@media screen and (max-width: 992px) {
				min-width: auto;
				white-space: nowrap;
			}

			@media screen and (max-width: 750px) {
				max-width: calc(50% - 0.5rem);
				flex: 1;

				&.button {
					max-width: 100%;
					margin-bottom: 1.5rem;
				}
			}

			@media screen and (max-width: 650px) {
				max-width: 100%;
				width: 100%;
				flex: auto;
			}
		}

		@media screen and (max-width: 992px) {
			flex-wrap: wrap;
		}
	}
	@media screen and (max-width: 992px) {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-block: 1.25rem;
		overflow-y: auto;
	}
}
