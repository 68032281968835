footer {
	margin: 0;
	position: relative;
	z-index: 9997;
	color: #fbfbfb;
	background-image: url(../img/bg-footer.jpg);
	background-size: cover;
	background-position: 50% 50%;

	.footer-top {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 6.25rem 0;
		gap: 3.75rem;
		@media (max-width: 992px) {
			padding: 5rem 0;
		}
		@media (max-width: 680px) {
			padding: 3.75rem 0;
		}
	}

	.widget {
    max-width: 40%;
    @media (max-width: 992px) {
			max-width: 100%;
		}
		.widget-title {
			font-family: "Montserrat-Bold";
			color: #fff;
			margin-bottom: 1.5rem;
		}
		@media (max-width: 540px) {
			width: 100%;
		}
	}

	.call-link {
		width: 27.5rem;
		margin-top: 5rem;
		@media (max-width: 540px) {
			width: 100%;
			margin-top: 1.5rem;
		}
	}

	.footer-menu {
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 1rem;
		font-size: 1rem;

		li {
			width: calc(50% - 0.5rem);
			a {
				display: flex;
				font-size: 1.25rem;
				text-transform: lowercase;
				&:hover {
					color: #fff;
					opacity: 0.8;
				}
			}
		}
	}

	.footer-contacts {
		a {
			display: block;
			margin-bottom: 0.75rem;
			color: #fff;
		}
	}
}

.socials {
	margin: 2rem 0;
	a {
		display: block;
		width: 2rem;
		height: 2rem;
		img,
		svg {
			width: 100%;
			height: auto;
		}
	}
}


.recv{
	margin: 3rem 0 0;
	font-size: 0.75rem;
	opacity: 0.8;
}