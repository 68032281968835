.product-detail {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	margin-bottom: 3rem;

	.image {
		width: 42.625rem;
		position: relative;
		@media (max-width: 992px) {
			width: 100%;
			margin-bottom: 2rem;
		}
	}

	.summary {
		width: 30.5rem;
		padding: 1.5rem;

		@media (max-width: 992px) {
			width: 100%;
		}
		@media (max-width: 680px) {
			padding: 1.5rem 0;
		}
		hr {
			margin-top: 1.25rem;
			margin-bottom: 1.25rem;
			border: 0;
			border-top: 1px solid #edeef0;
		}
	}

	.product-title {
		font-family: "Montserrat-Bold";
		font-size: 1.5rem;
		margin-bottom: 0.625rem;
	}
	.explanation {
		color: #515456;
	}

	.price {
    width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 1.25rem;
		gap: 0.3125rem;
    position: relative;
		ins,
		bdi {
			color: #111;
			font-family: "Montserrat-Bold";
			font-size: 1.875rem;
			position: relative;
			text-decoration: none;
			font-weight: 700;

      &.fizik {
				width: 100%;
				color: #767980;
        display: none;
        &.active{
          display: block;
        }
 
			}
      &.no-fizik{
        display: none;
        &.active{
          display: block;
        }
      }
		}
		del,
		del bdi {
			color: #959aa6;
			font-family: "Montserrat-Regular";
			font-size: 0.875rem;
			text-decoration: line-through;
			order: 2;
			display: block;
			font-weight: normal;
		}

		.icon-image {
			display: none;
		}
    .check-nds{
      position: absolute;
      top: 0.25rem;
      right: 0;
      font-family: "Montserrat-ExtraBold";
    }
		.nds {
      margin-left: auto;
			display: inline-flex;
      align-items: center;
      justify-content: center;
			font-family: "Montserrat-ExtraBold";
      height: 2rem;
			font-size: 0.75rem;
			padding: 0.25rem 1rem;
			border-radius: 1.75rem;
			background: #6bdb93;
      position: absolute;
      top: 0.5rem;
      right: 0;
		}
	}

	.property {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem 1.25rem;
		margin: 1.25rem 0;
		.item {
			width: calc(33.33% - 0.875rem);
			@media (max-width: 680px) {
				width: calc(50% - 0.875rem);
			}
			.name {
				font-size: 0.875rem;
				color: #767980;
			}
			.feature {
				font-family: "Montserrat-Medium";
				font-size: 0.875rem;
			}
		}
	}

	.cost-text {
		margin: 1.25rem 0;
		font-size: 0.875rem;
		color: #515456;
	}

	.button {
		width: 100%;
		height: 4.25rem;
		margin-bottom: 1rem;
		font-size: 1.2rem;
		&.btn-calculate {
			border: 1px solid rgb(224, 225, 231);
			background: rgb(247, 247, 247);
			&:hover,
			&:active,
			&:focus {
				color: #fff;
				background-color: $dop-color;
				border-color: $dop-color;
			}
		}
	}

	.description {
		margin-top: 2rem;
		.title {
			font-family: "Montserrat-Medium";
			font-size: 1.2rem;
			color: $dop-color;
			text-decoration: underline;
			cursor: pointer;
			display: inline-flex;
			align-items: center;
			gap: 0.5rem;
			&::after {
				content: "";
				width: 0.5rem;
				height: 0.5rem;
				border-bottom: 2px solid $dop-color;
				border-right: 2px solid $dop-color;
				transform: rotate(45deg);
			}
		}
		.box {
			display: none;
		}
		&.active {
			.title {
				&:after {
					transform: rotate(-135deg);
				}
			}
			.box {
				display: block;
			}
		}
	}
}

.product-slider {
	margin-bottom: 0.625rem;
	position: relative;
	border: 0.25rem solid rgb(217, 217, 217);
	border-radius: 0.4375rem;
	box-shadow: 0.1875rem 0.25rem 0.475rem 0 rgba(0, 0, 0, 0.09);
	overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 5.5rem;
		z-index: 2;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 100%);
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 5.5rem;
		z-index: 2;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 100%);
	}
	.swiper {
		position: relative;
		z-index: 1;
	}
	.swiper-slide {
		display: block;

		aspect-ratio: 682/450;
		background-size: cover;
		background-position: 50% 50%;
	}

	.swiper-button-prev {
		left: 1.25rem;
		@media (max-width: 680px) {
			left: 0.625rem;
		}
	}
	.swiper-button-next {
		right: 1.25rem;
		@media (max-width: 680px) {
			right: 0.625rem;
		}
	}
}

.slider-menu {
	display: flex;
	flex-wrap: wrap;

	gap: 0.625rem;
	.pic {
		width: calc(100% / 4 - 0.5625rem);
		aspect-ratio: 107/80;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		border: 0.25rem solid rgb(217, 217, 217);
		border-radius: 0.2rem;
		cursor: pointer;
		@media (max-width: 992px) {
			width: calc(100% / 4 - 0.5625rem);
		}
		&:hover {
			border-color: $dop-color;
		}
		&.active {
			border-color: $global-color;
		}
		&.end {
			position: relative;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 0.65rem;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.5);
				z-index: 1;
			}
			span {
				font-family: "Montserrat-Medium";
				font-size: 0.875rem;
				color: #fff;
				position: relative;
				z-index: 2;
			}
		}
	}
}

.atributes {
	ul {
		list-style: none;
		padding: 0;
		margin: 1.5rem 0;
		width: 100%;
		li {
			margin: 0.85em 0;
			position: relative;
			font-size: 1rem;
			line-height: 1.2;
			letter-spacing: 0;
			padding-left: 1.5rem;
			&:before {
				content: "";
				width: 0.75rem;
				height: 0.75rem;
				background: #e5002b;
				border-radius: 0.125rem;
				position: absolute;
				left: 0;
				top: 0.3rem;
			}
		}
	}
}

.fancybox__container {
	z-index: 99999 !important;
}
.fancybox__backdrop {
	background: #000;
	opacity: 0.85;
}

.section-complects {
	margin: 0;
	padding: 5rem 0;
	background-color: #f1f3f7;
	border-top: 1px solid #eaebf2;
	border-bottom: 1px solid #eaebf2;
	.title {
		font-family: "Montserrat-Bold";
		text-transform: uppercase;
		font-size: 1.5rem;
		margin-bottom: 3rem;
	}
	.complects {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		.item {
			width: calc(20% - 1rem);
			font-size: 0.875rem;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			&:before {
				content: "";
				display: block;
				width: 1rem;
				height: 1rem;
				background-image: url(../img/icons/check.svg);
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}

.section-views {
	position: relative;
	padding: 5rem 0;
	margin: 0;

	.products {
		margin-top: 3.75rem;
	}
}
