/*------ accordion ------*/
.section-accordion {
	display: flex;
	flex-wrap: wrap;
	gap: 3.75rem;
	position: relative;
	z-index: 10;
	@media (max-width: 992px) {
		gap: 3rem 1.5rem;
	}
	.image {
		width: calc(50% - 1.875rem);
		@media (max-width: 992px) {
			width: calc(50% - 0.75rem);
		}
		@media (max-width: 680px) {
			width: 100%;
		}
		img {
			width: 100%;
			aspect-ratio: 1/1;
			object-fit: cover;
			border-radius: 0.5rem;
		}
	}
}
.accordion {
	width: calc(50% - 1.875rem);
	display: flex;
	flex-direction: column;
	@media (max-width: 992px) {
		width: calc(50% - 0.75rem);
	}
	@media (max-width: 680px) {
		width: 100%;
	}
	.accordion-box {
		background: #ffffff;
		border-radius: 0.5rem;
		break-inside: avoid;
		margin-bottom: 2.5rem;
		@media (max-width: 992px) {
			margin-bottom: 1.5rem;
		}
		.top {
			width: 100%;
			height: 4.25rem;
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			//text-align: center;
			font-family: "Montserrat-Bold";
			font-size: 1rem;
			line-height: 1.3;
			text-transform: uppercase;
			border-radius: 0.25rem;
			box-shadow:
				0.375rem 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.2),
				inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.25);
			color: #fff;
			background-color: $global-color;
			border: 1px solid transparent;
			padding: 0.75rem 2.5rem;
			gap: 1.5rem;
			position: relative;
			cursor: pointer;
			@media (max-width: 992px) {
				font-size: 0.875rem;
			}
			&:hover,
			&:active,
			&:focus {
				color: #fff;
				background-color: $dop-color;
				border-color: $dop-color;
				box-shadow:
					0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2),
					inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.05);
			}
			&:after {
				content: "";
				background: url("../img/icons/plus.svg");
				min-width: 1rem;
				width: 1rem;
				height: 1rem;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				transition: transform 0.5s;
			}
		}

		.accordion-body {
			width: 100%;
			max-height: 0;
			overflow: hidden;
			transition: all 0.2s ease-out;
			box-sizing: border-box;
			background: #fff;
			border-radius: 0 0 0.5rem 0.5rem;
			.block {
				padding: 2.5rem;
				font-size: 1rem;
				line-height: 1.4;
				border-radius: 0 0 0.5rem 0.5rem;

				@media (max-width: 414px) {
					padding: 0 1rem 0.9375rem;
				}
			}
		}
		&.active {
			box-shadow: 0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2);
		}
		&.active .top {
			border-radius: 0.5rem 0.5rem 0 0;
		}
		&.active .body {
			border-radius: 0 0 0.5rem 0.5rem;
		}
		&.active .top:after {
			transform: rotate(90deg);
			transition: transform 0.5s;
		}
	}

	.button {
		margin-top: auto;
		@media (max-width: 680px) {
			margin-top: 1.5rem;
		}
	}
}
