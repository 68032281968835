section {
	margin: 8rem 0;

	@media (max-width: 922px) {
		margin: 3.75rem 0;
	}
	@media (max-width: 680px) {
		margin: 3rem 0;
	}
}
