/*----------navigation-------------*/
.nav {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;
		gap: 1.8rem;

		li {
			a {
				display: flex;
				font-size: 1rem;
				//text-transform: lowercase;

				color: $font-color;
				&:hover,
				&:focus {
					color: $global-color;
					opacity: 0.8;
				}
				&:active {
					color: $font-color-sub;
					opacity: 1;
				}
			}
		}
	}
}

header {
	.nav {
		@media (max-width: 992px) {
			display: none;
		}
	}
}

.nav-mobile {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 9997;
	background: #fff;
	padding: 10rem 1.5rem 1.5rem;
	display: none;
	overflow-y: auto;

	ul.catalog-menu {
		padding: 0;
		margin: 2rem 0;
		list-style: none;
		li.dn {
			> a {
				display: none;
			}
		}
		> li {
			width: 100%;
			.sub-menu {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 0.5rem;
				padding: 1.5rem 0 0;
				list-style: none;
				li {
					width: 100%;
					a {
						font-family: "Montserrat-SemiBold";
						font-size: 1rem;
						color: $font-color;
						&:hover {
							color: $dop-color;
						}
					}
				}
			}
			&:last-child {
				width: 100%;
				.sub-menu {
					flex-direction: row;
					flex-wrap: wrap;
					gap: 0.5rem;
					li {
						width: auto;
						a {
							display: flex;
							align-items: center;
							font-family: "Montserrat-Medium";
							font-size: 0.875rem;
							padding: 0.25rem 0.75rem;
							background: #f1f3f7;
						}
					}
				}
			}
		}
	}

	.top-menu {
		padding: 0;
		margin: 0;
		list-style: none;
		color: $font-color-sub;
		font-size: 1.3rem;
		font-family: Montserrat-Medium, sans-serif;
		font-weight: 500;

		li + li {
			margin-top: 1.25rem;
		}
	}

	.socials {
		.title {
			font-size: 1.145rem;
		}
	}

	.mobile-contacts {
		font-size: 1.4rem;
		margin-bottom: 2rem;

		ul {
			margin: 1.5rem 0;
			padding: 0;
			list-style: none;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			li:nth-child(2) {
				br {
					display: none;
				}
			}
		}
		@media screen and (max-width: 992px) {
			margin: 3.5rem 0;
		}
	}
	.phone-block .phone,
	.phone-block-mob .phone {
		font-size: 1.25rem;
		font-family: Montserrat-Bold, sans-serif;
		color: $global-color;
	}
	.socials {
		justify-content: flex-start;
	}

	&.active {
		display: block;
		overflow: auto;
	}

	.top {
		margin-bottom: 1.5rem;

		.close {
			margin-bottom: 1.2rem;
			display: flex;
			align-items: center;
			gap: 0.6rem;

			&:before {
				content: "";
				width: 0.65rem;
				height: 0.65rem;
				border-bottom: 0.125rem solid #a2a2a2;
				border-left: 0.125rem solid #a2a2a2;
				transform: rotate(45deg);
				cursor: pointer;
				transition: 0.4s;
			}
		}

		.name {
			font-family: "Montserrat-Semibold";
			font-size: 1.5rem;
		}
	}
}

.nav-catalog {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	padding: 2rem 0;
	background: #fff;
	box-shadow: 0 0.8125rem 1rem rgba(212, 215, 231, 0.4);
	display: none;
	&.active {
		display: block;
	}
	ul {
		display: flex;
		flex-wrap: wrap;

		gap: 3rem 2rem;
		padding: 0;
		margin: 0;
		list-style: none;
		li.dn {
			> a {
				display: none;
			}
		}
		> li {
			width: calc(33.33% - 2rem);
			.sub-menu {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 0.5rem;
				border-top: 1px solid $dop-color;
				padding-top: 1.5rem;
				li {
					width: 100%;
					a {
						font-family: "Montserrat-SemiBold";
						font-size: 1rem;
						color: $font-color;
						&:hover {
							color: $dop-color;
						}
					}
				}
			}
			&:last-child {
				width: 100%;
				.sub-menu {
					flex-direction: row;
					flex-wrap: wrap;
					gap: 0.5rem;
					li {
						width: auto;
						a {
							display: flex;
							align-items: center;
							font-family: "Montserrat-Medium";
							font-size: 0.875rem;
							padding: 0.25rem 0.75rem;
							background: #f1f3f7;
						}
					}
				}
			}
		}
	}
}
