.socials {
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	padding-left: 0;
	color: #515456;
	.title {
		width: 100%;
		font-family: "Montserrat-Medium";
		font-size: 0.875rem;
		color: #111111;
	}
	a {
		display: block;
		width: 1.5rem;
		height: 1.5rem;
	}
}
