.post-single{
	article{
		font-size: 1.125rem;
	}
}

.post-date{
	font-size: 0.875rem;
	color: $font-color-light;
	margin: 1rem 0;
}

.singe-image{
	margin: 1.375rem 0 2.25rem;
	border-radius: 0.5rem;
	width: 100%;
	aspect-ratio: 793/458;
	background-size: cover;
	background-position: 50% 50%;
}