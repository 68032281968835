.section-about {
	border-top: 0.25rem solid #f1f3f7;
	border-bottom: 0.25rem solid #f1f3f7;
	padding: 4.25rem 0;
	min-height: 40rem;
	position: relative;
	margin-bottom: 8rem;
	@media (max-width: 992px) {
		margin-bottom: 0;
		border-bottom: none;
		padding: 3rem 0;
	}
	.block {
		max-width: 41.25rem;
	}
	.image {
		background-image: url(../img/about.jpg);
		background-size: cover;
		background-position: 50% 40%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 65%;
		width: 50rem;
		@media (max-width: 992px) {
			width: 100%;
			height: 24rem;
			position: static;
		}
		@media (max-width: 540px) {
			height: 16rem;
		}
		img {
			position: absolute;
			left: -8.75rem;
			bottom: 0;
			width: auto;
			height: 43.75rem;
			max-width: 200%;
			@media (max-width: 992px) {
				display: none;
			}
		}
	}

	.cite {
		margin: 3rem 0;
		padding: 0 1.5rem;
		position: relative;

		&:before {
			content: "";
			width: 0.625rem;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 1px;
			box-shadow:
				1px 0.125rem 0.45rem 0 rgba(0, 0, 0, 0.2),
				inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.25);
			background: $global-color;
		}
	}
	.text {
		margin: 3rem 0;
		b {
			text-transform: uppercase;
		}
	}

	.line-about {
		min-height: 6.625rem;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: -100%;
			right: -100%;
			background: #f1f3f7;
		}
		.row {
			align-items: center;
			gap: 1.5rem;
			padding: 1rem 0;
			font-family: "Montserrat-Bold";
			position: relative;
			z-index: 10;
		}

		.pic {
			width: 4.375rem;
			height: 4.375rem;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow:
				0.25rem 0.25rem 0.5375rem 0 rgba(0, 0, 0, 0.2),
				inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.25);
			background: rgb(229, 0, 43);
			color: #fff;
		}
		.desc {
			width: calc(100% - 7.5rem);
		}
	}
}
