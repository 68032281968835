/*-----form-----*/

.forma {
	--border-radius: 0.625rem;
	--color-placeholder: $font-color-light;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	.wpcf7-spinner {
		display: none;
	}

	label {
		display: block;

		span {
			margin-top: 0.625rem;
			display: block;
		}
	}

	.wide-row {
		width: 100%;
		position: relative;
	}

	input {
		border: none;
		border-bottom: 1px solid #e0e1e7;
		background-color: #fff;
		border-radius: 0;
		width: 100%;
		height: 3.125rem;
		padding: 0.8125rem 0;
		font-size: 1rem;
		line-height: 1;
		color: #000000;
		box-shadow: none;
		margin: 0;
	}

	textarea {
		width: 100% !important;
		height: 6.25rem;
		padding: 0.625rem 0;
		font-size: 1.125rem;
		border: none;
		border-bottom: 1px solid #e0e1e7;
		background-color: #fff;
		border-radius: 0;
		box-sizing: border-box;
	}

	.inscription {
		font-size: 0.75rem;
		color: $font-color-gray;

		a {
			color: $global-color;
		}
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	form {
		--gap: 1.25rem;
		display: flex;
		gap: var(--gap);
		flex-wrap: wrap;
		padding: 1.875rem;
		background: #ffffff;
		border-radius: 1.25rem;

		.block-name,
		.block-phone {
			grid-column: span 1;
			width: calc(50% - var(--gap) / 2);
		}

		.block-phone {
			grid-column: span 1;
		}

		.block-area {
			grid-column: span 2;
			width: 100%;

			.comments {
				resize: none;
				overflow-y: auto;
			}
		}

		.submits {
			grid-column: span 1;
			max-width: 12rem;
			height: 3.125rem;
		}

		.inscription {
			grid-column: span 1;
			flex: 1;
		}
		@media screen and (max-width: 992px) {
			& [class^="block"] {
				width: 100%;
			}
		}
	}
}

form.submitting .wpcf7-spinner {
	display: inline-block;
}

::-webkit-input-placeholder {
	color: #919395;
}

::-moz-placeholder {
	color: #919395;
}

:-moz-placeholder {
	color: #919395;
}

:-ms-input-placeholder {
	color: #919395;
}

:focus::-webkit-input-placeholder {
	color: transparent;
}

:focus::-moz-placeholder {
	color: transparent;
}

:focus:-moz-placeholder {
	color: transparent;
}

:focus:-ms-input-placeholder {
	color: transparent;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
	display: none;
}

.wpcf7-not-valid-tip {
	color: #dc3232;
	font-size: 0.75rem;
	font-weight: normal;
	display: block;
	margin-top: 0 !important;
}
