/*----header-----*/
header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9998;
	transition: 0.6s;
	background: $global-color;
	color: #fff;
	box-shadow: 0 0.8125rem 1rem rgba(212, 215, 231, 0.2);

	@media (max-width: 992px) {
		position: relative;
	}

	&.sticky {
		transition: 0.6s;
		box-shadow: 0 0.8125rem 1rem rgba(212, 215, 231, 0.4);
		.header-top {
			margin-top: -3rem;
			transition: 0.6s;
		}
	}
}
.header {
	min-height: 5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 5;
	gap: 0.75rem 2rem;
	transition: 0.6s;
	@media (max-width: 992px) {
		gap: 1.875rem;
		min-height: 6rem;
	}
}
.header-top {
	background: #f1f3f7;
	transition: 0.6s;
	@media (max-width: 992px) {
		display: none;
	}
	.header {
		min-height: 3rem;
		justify-content: flex-end;
	}
}

.logo-box {
	.logo {
		width: 8.9rem;
		color: #fff;
		display: inline-flex;
		align-items: center;
		@media (max-width: 992px) {
			width: 8.5rem;
		}
		@media (max-width: 480px) {
			width: 6rem;
		}
		img {
			width: 100%;
		}
	}
	.slogan {
		font-size: 0.875rem;
		@media (max-width: 992px) {
			display: none;
		}
	}
}

.toggle-catalog {
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	border: none;
	background: transparent;
	padding: 0;
	font-family: "Montserrat-SemiBold";
	@media (max-width: 992px) {
		display: none;
	}
	.ico {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 1.35rem;
		height: 1.35rem;
		position: relative;
	}

	span {
		display: block;
		width: 1.35rem;
		height: 0.125rem;
		background: #fff;
	}
}

.phone-block {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	align-items: flex-end;
	@media (max-width: 480px) {
		gap: 0.2rem;
	}
	.adress {
		font-size: 0.875rem;
	}

	.phone {
		font-size: 1.25rem;
		font-family: Montserrat-Bold, sans-serif;
		color: #fff;
		@media screen and (max-width: 992px) {
			font-size: 1.25rem;
		}
	}

	@media screen and (max-width: 992px) {
		align-items: baseline;
	}
}

.toggle {
	display: none;

	@media (max-width: 992px) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 1.625rem;
		height: 1.625rem;
		position: relative;
	}

	span {
		display: block;
		width: 1.625rem;
		height: 0.125rem;
		background: #fff;
	}

	&.active {
		span:nth-child(2) {
			display: none;
		}

		span:nth-child(1),
		span:nth-child(3) {
			content: "";
			position: absolute;
			transition: 0.6s;
			top: 0.5rem;
		}

		span:nth-child(1) {
			transform: rotate(45deg);
		}

		span:nth-child(3) {
			transform: rotate(-45deg);
		}
	}
}

.site-bar {
	display: none;
	@media (max-width: 992px) {
		display: flex;
		align-items: center;
		gap: 2rem;
	}

	.toggle-search {
		width: 1.625rem;
		height: 1.625rem;
		background-image: url(../img/icons/search.svg);
		background-size: contain;
		background-repeat: no-repeat;
		&.active {
			width: 1.25rem;
			height: 1.25rem;
			background-image: url(../img/icons/close.svg);
		}
		img,
		svg {
			width: 100%;
		}
	}
}
.search-box {
	width: 50%;
	position: relative;
	@media (max-width: 992px) {
		display: none;
		&.active {
			width: 100%;
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background: #919395;
		}
	}
	.search-form {
		display: flex;
	}
	input {
		background: rgba(212, 215, 231, 0.2);
		color: #fff;
		border: none;
		flex-grow: 1;
		font-size: 0.75rem;
		padding: 0.5rem 1rem;
		@media (max-width: 992px) {
			font-size: 1rem;
			padding: 1rem;
		}
	}

	input::-webkit-input-placeholder {
		color: #fff;
	}

	input::-moz-placeholder {
		color: #fff;
	}

	input:-moz-placeholder {
		color: #fff;
	}

	input:-ms-input-placeholder {
		color: #fff;
	}

	input:focus::-webkit-input-placeholder {
		color: transparent;
	}

	input:focus::-moz-placeholder {
		color: transparent;
	}

	input:focus:-moz-placeholder {
		color: transparent;
	}

	input:focus:-ms-input-placeholder {
		color: transparent;
	}

	button {
		background: rgba(212, 215, 231, 0.2);
		color: #fff;
		border: none;
		width: 2rem;
		padding: 0.5rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 992px) {
			width: 2.5rem;
		}
		&:hover {
			background: rgba(212, 215, 231, 1);
			color: #e5002b;
		}
		svg,
		img {
			width: 100%;
			height: auto;
		}
	}
	.search-result {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
	}
	.search-result-box {
		padding: 1.5rem;
		background: #fff;
		box-shadow: 0 0.8125rem 1rem rgba(212, 215, 231, 0.4);
		display: flex;
		flex-direction: column;

		p {
			color: #000;
			font-size: 0.875rem;
		}
		a.item {
			display: flex;
			gap: 1rem;
			color: #000;
			font-size: 0.875rem;
			border-bottom: 1px solid #e0e1e7;
			padding: 0.3rem 0;
			&:hover {
				color: $global-color;
			}
			.image {
				min-width: 2.5rem;
				width: 2.5rem;
				img {
					width: 100%;
					aspect-ratio: 1/1;
					object-fit: contain;
				}
			}
			.price {
				text-align: right;
				min-width: 10rem;
				width: 10rem;
				margin-left: auto;
				color: #919395;
				font-family: "Montserrat-SemiBold";
			}
		}
		a.result-all{
			display: flex;
			justify-content: center;
			gap: 1rem;
			font-family: "Montserrat-SemiBold";
			color: $dop-color;
			font-size: 1rem;
			padding: 0.5rem;
		}
	}
}

.aws-search-result .aws_result_price {
	font-family: "Montserrat-ExtraBold";
	color: #000;
}
.aws-search-result .aws_result_excerpt {
	display: none;
}
.aws-search-result .aws_result_title {
	display: block;
	font-weight: 400;
	margin-bottom: 3px;
	color: #000;
}
.aws-search-result .aws_result_content {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.aws-search-result .aws_search_more a {
	line-height: 40px;
	display: block;
	text-decoration: none;
	border: 0;
	text-align: center;
	color: #fff;
	background: #e5002b;
	&:hover {
		background: $dop-color;
	}
}
