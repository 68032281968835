.services {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1.5rem 2.5rem;

	.item {
		width: calc(33.33% - 1.75rem);
		position: relative;
		display: block;
		border: 0.25rem solid rgb(217, 217, 217);
		border-radius: 0.4375rem;
		box-shadow: 0.1875rem 0.25rem 0.475rem 0 rgba(0, 0, 0, 0.09);
		@media (max-width: 992px) {
			width: 100%;
			display: flex;
		}
		@media (max-width: 540px) {
			flex-direction: column;
			height: auto;
		}
	}

	.image {
		@media (max-width: 992px) {
			width: 50%;
		}
		@media (max-width: 540px) {
			width: 100%;
		}
		img {
			width: 100%;
			aspect-ratio: 352/235;
			object-fit: cover;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		padding: 2.5rem 2.875rem;
		background-color: #ffffff;
		transition: 0.3s;
		gap: 1.5rem;
		min-height: calc(100% - 15.625rem);
		@media (max-width: 992px) {
			width: 50%;
			padding: 1.5rem;
		}
		@media (max-width: 540px) {
			width: 100%;
			min-height: 2rem;
		}
		.title {
			font-family: "Montserrat-ExtraBold";
			font-size: 1.5rem;
			@media (max-width: 992px) {
				font-size: 1.2rem;
			}
		}
		.button {
			margin-top: auto;
			min-width: 100%;
			font-size: 1rem;
		}
	}

	@media screen and (max-width: 992px) {
		flex-wrap: wrap;
	}
}
