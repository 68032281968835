.about {
	margin-top: 3.75rem;
	margin-bottom: 11rem;

	@media (max-width: 992px) {
		margin-top: 3rem;
		margin-bottom: 5rem;
	}
	article {
		font-size: 1.125rem;
		line-height: 1.6;
	}
}
