.products {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 2.5rem 1rem;
	padding: 0;
}

/*-----product-----*/
.product-item {
	display: flex;
	flex-direction: column;
	width: calc(25% - 0.75rem);
	position: relative;
	list-style: none;
	border-radius: 0.4375rem;
	box-shadow: 0.1875rem 0.25rem 0.475rem 0 rgba(0, 0, 0, 0.05);
	overflow: hidden;
	&:hover {
		box-shadow: 0 0.8125rem 1rem rgba(212, 215, 231, 0.3);
		a {
			color: $font-color;
		}
	}

	//? исправил для страницы каталог

	@media (max-width: 992px) {
		width: calc(33.33% - 0.84rem);
	}

	@media screen and (max-width: 768px) {
		width: calc(50% - 0.63rem);
	}

	@media (max-width: 500px) {
		min-width: 100%;
		width: 100%;
		margin: 0 auto;
	}

	.image {
		position: relative;
		z-index: 5;
		display: block;
		background-size: contain;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		border-bottom: 1px solid #e0e1e7;

		img {
			width: 100%;
			aspect-ratio: 220/160;
			object-fit: cover;
		}
	}

	.title {
		display: block;
		min-height: 2.75rem;
		margin-bottom: 0.5rem;
		font-family: "Montserrat-Bold";
		font-size: 1rem;
		// min-height: 5.375rem;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		&:hover {
			color: $global-color;
		}
	}

	.type {
		font-family: "Montserrat-Bold";
		color: #919395;
		font-size: 0.875rem;
		margin-bottom: 0.625rem;
	}
	.content {
		padding: 1rem;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.feature {
		font-size: 0.75rem;
		color: #919395;
		columns: 2;
		column-width: 6.875rem;
		column-gap: 1.25rem;
		border-top: 1px solid #e0e1e7;
		padding-top: 0.9375rem;
		margin-bottom: 1rem;
		.item {
			margin-bottom: 0.5rem;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
			break-inside: avoid-column;
			.name {
				color: #111111;
			}
		}
		@media (max-width: 992px) {
			column-width: 4rem;
		}
	}

	.price {
		display: flex;
		flex-wrap: wrap;
		gap: 0.125rem;
		align-items: center;
		min-height: 1.75rem;
		border-top: 1px solid #e0e1e7;
		padding-top: 0.9375rem;
		margin-top: auto;
		position: relative;
		.null {
			font-family: "Montserrat-Bold";
			font-size: 1.2rem;
		}
		ins,
		bdi {
			color: #111;
			font-family: "Montserrat-ExtraBold";
			font-size: 1.2rem;
			position: relative;
			text-decoration: none;

			font-weight: 700;
			&.fizik {
				width: 100%;
				color: #767980;
				display: none;
				&.active {
					display: block;
				}
			}
			&.no-fizik {
				display: none;
				&.active {
					display: block;
				}
			}
		}
		del,
		del bdi {
			color: #959aa6;
			font-family: "Montserrat-Regular";
			font-size: 0.875rem;
			text-decoration: line-through;
			order: 2;
			display: block;
			font-weight: normal;
		}

		.icon-image {
			display: none;
		}
		.check-nds {
			position: absolute;
			top: 0.75rem;
			right: 0;
			font-family: "Montserrat-ExtraBold";
		}
		.nds {
			margin-left: auto;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-family: "Montserrat-ExtraBold";
			height: 2rem;
			font-size: 0.75rem;
			padding: 0.25rem 1rem;
			border-radius: 1.75rem;
			background: #6bdb93;
			position: absolute;
			top: 0.875rem;
			right: 0;
		}
	}
}

.home {
	.product-item {
		.stock {
			display: none;
		}
	}
}

.checkbox-ya {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	position: relative;
	vertical-align: middle;
	user-select: none;
}
.checkbox-ya .checkbox-ya-switch {
	display: inline-block;
	box-sizing: border-box;
	width: 6rem;
	height: 2rem;
	margin: 0 auto;
	position: relative;
	background: linear-gradient(90deg, #6bdb93 0%, #6bdb93 50%, #e8e9e9 50%, #e8e9e9 200%);
	background-position: -6rem 0;
	background-size: 200% 100%;
	border-radius: 1.75rem;
	font-family: "Montserrat-ExtraBold";
	font-size: 0.75rem;
	color: #000;
	transition: all 150ms ease-in;
}
.checkbox-ya .checkbox-ya-switch:before {
	content: "";
	display: block;
	box-sizing: border-box;
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
	position: absolute;
	top: 50%;
	left: -1px;
	text-indent: -100%;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(-50%);
	transition: all 150ms ease-in;
}
.checkbox-ya .checkbox-ya-feature {
	position: relative;
	display: block;
	height: 2rem;
	line-height: 2rem;
	overflow: hidden;
}
.checkbox-ya .checkbox-ya-feature:before,
.checkbox-ya .checkbox-ya-feature:after {
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: all 150ms ease-in;
}
.checkbox-ya .checkbox-ya-feature:before {
	content: attr(data-label-on);
	left: -60%;
}
.checkbox-ya .checkbox-ya-feature:after {
	content: attr(data-label-off);
	right: 0.5rem;
}
.checkbox-ya input[type="checkbox"] {
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.checkbox-ya input[type="checkbox"]:checked + .checkbox-ya-switch {
	background-position: 0 0;
	border-color: rgba(153, 122, 0, 0.5);
}
.checkbox-ya input[type="checkbox"]:checked + .checkbox-ya-switch:before {
	left: calc(100% - 27px);
}
.checkbox-ya input[type="checkbox"]:checked + .checkbox-ya-switch .checkbox-ya-feature:before {
	left: 13px;
}
.checkbox-ya input[type="checkbox"]:checked + .checkbox-ya-switch .checkbox-ya-feature:after {
	right: -60%;
}

/* Hover */
.checkbox-ya input[type="checkbox"]:not(:disabled) + .checkbox-ya-switch:hover:before {
	border-color: rgba(0, 0, 0, 0.6);
}
.checkbox-ya input[type="checkbox"]:not(:disabled) + .checkbox-ya-switch:hover {
	cursor: pointer;
}

/* Disabled */
.checkbox-ya input[type="checkbox"]:disabled + .checkbox-ya-switch {
	border-color: rgba(0, 0, 0, 0.1);
	filter: grayscale(70%);
}
.checkbox-ya input[type="checkbox"]:disabled + .checkbox-ya-switch .checkbox-ya-feature {
	color: #999;
}

/* Focus */
.checkbox-ya.focused .checkbox-ya-switch:before {
	border-width: 2px;
	border-color: #ffdb4d;
}
