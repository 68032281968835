.cat-page {
	position: relative;
	margin: 3rem 0 0;
}
.cars-nav {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	margin-bottom: 1rem;
	padding: 0.9375rem;
	a {
		font-family: "Montserrat-SemiBold";
		font-size: 0.85rem;
		text-transform: uppercase;
		color: $dop-color;
		&:hover {
			color: $font-color-sub;
		}
	}
}
.toggle-filter {
	display: none;
	@media (max-width: 992px) {
		display: inline-flex;
		margin-bottom: 1.5rem;
	}
	@media (max-width: 480px) {
		max-width: 100%;
		width: 100%;
	}
}
.sidebar {
	width: 100%;
	border: 0.25rem solid rgb(236, 236, 236);
	border-radius: 0.4375rem;
	background: rgb(255, 255, 255);
	padding: 2.5rem 1.25rem;
	margin-bottom: 2rem;
	position: relative;
	z-index: 888;
	transition: 0.4s;
	@media (max-width: 992px) {
		width: 28rem;
		height: 100vh;
		position: fixed;
		top: 0;
		bottom: 0;
		left: -100%;
		z-index: 99999;
		padding: 0;
		&.active {
			left: 0;
			transition: 0.4s;
		}
	}
	@media (max-width: 540px) {
		width: 100%;
	}
}
.top-category {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 15;
	@media (max-width: 680px) {
		flex-wrap: wrap;
	}
}
.products {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem 1rem;
	padding: 0;
	margin: 2rem 0;
	position: relative;
	z-index: 10;
	@media (max-width: 992px) {
		gap: 1.5rem 1rem;
	}
}
.woocommerce-no-products-found {
	margin-bottom: 4rem;
}
.woocommerce-notices-wrapper {
	display: none !important;
}

.woocommerce-result-count {
	margin: 0;

	@media (max-width: 992px) {
		font-size: 1rem;
	}
	@media (max-width: 680px) {
		width: 100%;
		margin-bottom: 1rem;
	}
}

.logos-box {
	width: 16rem;
	position: absolute;
	z-index: 10;
	top: -12rem;
	bottom: -12rem;
	right: 2rem;
	display: flex;
	gap: 1rem;
	overflow: hidden;
	@media (max-width: 992px) {
		position: static;
		width: 100%;
		margin-top: 3rem;
		flex-direction: column;
		gap: 0.75rem;
		overflow: visible;
	}
	@media (max-width: 680px) {
		margin-top: 2.25rem;
		gap: 0.375rem;
	}
}

.marquee-down {
	height: 20rem;
	width: calc(50% - 0.5rem);
	&:nth-child(2) {
		margin-top: 2rem;
		height: 22rem;
	}
	@media (max-width: 992px) {
		display: none;
	}
}

.marquee-left {
	display: none;
	width: 100%;
	&:last-child {
		width: 110%;
		margin-left: -5%;
	}
	@media (max-width: 992px) {
		display: flex;
		flex-wrap: nowrap;
	}
	.js-marquee-wrapper {
		display: flex;
		flex-wrap: nowrap;
		gap: 0.75rem;
	}
	.js-marquee {
		display: flex;
		flex-wrap: nowrap;
		flex-shrink: 0;
		gap: 0.75rem;
		margin: 0 !important;
	}
	.item {
		flex-shrink: 0;
	}
}

.marquee-down,
.marquee-left {
	.item-img {
		aspect-ratio: 1/1;
		height: 7.5rem;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		margin-bottom: 1rem;
		@media (max-width: 992px) {
			width: 5rem;
			height: 5rem;
			margin-bottom: 0;
		}

		img {
			height: 4rem;
			width: auto;

			@media (max-width: 992px) {
				height: 3.5rem;
			}
		}
	}
}

.search-results {
	.section-intro {
		&:after {
			display: none;
		}
		.intro .block {
			max-width: 100%;
		}
	}
}
