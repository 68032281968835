/*-----breadcrumbs-----*/
.breadcrumbs {
	margin:  1.5rem 0;
	font-size: 0.75rem;
	line-height: 1.3;
	margin-left: -1rem;
	color: #767980;
	@media (max-width: 991px) {
		overflow-x: auto;
		font-size: 0.8rem;
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		padding-bottom: 0.5rem;
	}

	nav {
		display: flex;
		align-items: center;
		white-space: nowrap;
		@media (max-width: 992px) {
			flex-wrap: nowrap;
			width: auto !important;

			span,
			a {
				flex-wrap: nowrap;
				width: auto !important;
				display: flex;
				align-items: center;
			}
		}
	}
	span {
		display: inline-flex;
		margin: 0 0.35rem;
		white-space: nowrap;

		@media (max-width: 992px) {
			margin: 0 0.25rem;
		}
	}
	a {
		display: inline-flex;
		align-items: center;
		margin: 0 0.35rem;
		white-space: nowrap;
		@media (max-width: 992px) {
			margin: 0 0.25rem;
		}

		&:hover {
			color: $global-color;
			text-decoration: none;
		}
	}
}
