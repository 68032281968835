.section-line {
	min-height: 7.5rem;
	box-shadow: 0.25rem 0.25rem 0.975rem 0 rgba(0, 0, 0, 0.17);
	background: rgb(229, 0, 43);
	color: #fff;
	display: flex;
	align-items: center;
	font-family: "Montserrat-ExtraBold";
	font-size: 1.5rem;
	position: relative;
	z-index: 10;
	@media (max-width: 992px) {
		min-height: 5.5rem;
		font-size: 1.2rem;
	}
}

.marquee {
	display: flex;
	flex-wrap: nowrap;
	width: 150%;
	margin-left: -25%;

	.js-marquee-wrapper {
		display: flex;
		flex-wrap: nowrap;
	}
	.js-marquee {
		display: flex;
		flex-wrap: nowrap;
		flex-shrink: 0;
	}
	.item {
		display: inline-flex;
		margin: 0 1rem;
		gap: 2rem;

		flex-shrink: 0;
		position: relative;

		&:after {
			content: "•";
		}
	}
}
