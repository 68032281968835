.home-title {
	font-family: "Montserrat-ExtraBold";
	font-size: 2.8rem;
	text-transform: uppercase;
	@media (max-width: 992px) {
		font-size: 2.8rem;
		line-height: 1.2;
	}
}

.page-title {
	font-family: "Montserrat-ExtraBold";
	font-size: 2rem;
	text-transform: uppercase;
	@media (max-width: 992px) {
		font-size: 1.8rem;
		line-height: 1.2;
	}
}

.section-title {
	font-family: "Montserrat-ExtraBold";
	font-size: 2rem;
	text-transform: uppercase;
	position: relative;
	z-index: 10;
	@media (max-width: 992px) {
		font-size: 2.4rem;
	}
	@media (max-width: 540px) {
		font-size: 2rem;
	}
}

.post-title {
	font-family: "Montserrat-Bold";
	font-size: 1.875rem;
	line-height: 1.33;
}

.block-title {
	font-family: "Montserrat-Bold";
	font-size: 2.5rem;
	line-height: 1.33;
	@media (max-width: 992px) {
		font-size: 2rem;
	}
}

.widget-title {
	font-family: "Montserrat-Bold";
	font-size: 1.125rem;
}
