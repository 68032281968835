.section-link {
	color: #919395;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	font-size: 0.875rem;
	line-height: 1;
	//align-self: center;

	img,
	svg {
		width: 0.5rem;
		height: auto;
	}
}
