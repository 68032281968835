article {
	color: #313335;
	margin-bottom: 5rem;
	h2,
	h3,
	h4,
	
	h5 {
		margin: 1.5em 0 1.875rem;
		width: 100%;

		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	h2 {
		font-size: 1.875rem;
		line-height: 1.4;
		font-family: Montserrat-Bold, sans-serif;
		font-weight: 700;

		@media (max-width: 992px) {
			font-size: 1.8rem;
			line-height: 1.4;
		}
		@media (max-width: 640px) {
			font-size: 1.6rem;
		}
	}
	h3 {
		font-size: 1.75rem;
	}
	h4 {
		font-size: 1.5rem;
	}

	p {
		margin: 1.5em 0 1.875rem;
		width: 100%;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	b,
	strong {
		font-family: "Montserrat-SemiBold";
	}
	a {
		color: inherit;
		text-decoration: underline;
		color: $global-color;

		&:hover {
			text-decoration: none;
			color: $dop-color;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 1.5em 0 4.5em;
		width: 100%;
		font-size: 0.9375rem;
		li {
			padding-left: 1.5rem;
			margin: 1.4375rem 0;
			position: relative;

			&:before {
				content: "";
				width: 0.75rem;
				height: 0.75rem;
				background: $global-color;
				border-radius: 0.125rem;
				position: absolute;
				left: 0;
				top: 0.5rem;
			}
		}
	}

	ol {
		list-style: decimal;
		padding: 0 2rem;
		margin: 1.5em 0 1.5em;
		width: 100%;
		li {
			margin: 0.5rem 0;
			display: list-item;
		}
	}

	blockquote {
		width: 100%;
		margin: 1.5em 0;
		padding: 1.875rem 4.375rem;
		background: #fff;
		position: relative;
		border-left: 0.25rem solid $global-color;
		color: $font-color-sub;
		@media (max-width: 640px) {
			padding: 1.5rem 1rem;
		}
		p {
			font-style: italic;
			margin: 0;
		}
		cite {
			text-align: right;
			font-size: 1rem;
			color: $font-color-light;
		}
	}

	.wp-block-image {
		margin: 1.5em 0;
	}
	.wp-block-table {
		margin: 1.5em 0;
		padding: 1.875rem 2.8125rem;
		background: #fff;
		border-radius: 0.75rem;
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;

		@media (max-width: 640px) {
			padding: 1.5rem 1.5rem 3rem;
		}
		.wp-element-caption {
			order: 1;
			font-size: 1.375rem;
			margin-bottom: 0.5rem;
		}
		table {
			order: 2;
		}
		@media (max-width: 992px) {
			overflow-x: auto;
			padding-bottom: 0.9375rem;
			display: flex;
			justify-content: flex-start;
			table {
				min-width: 45rem;
				width: 45rem;
				th,
				td {
					padding: 1rem 0;
					font-size: 1rem;
				}
			}
		}
	}
	@media screen and (max-width: 900px) {
		margin-bottom: 2rem;
	}
}

table {
	width: 100%;

	th {
		font-weight: normal;
		text-align: left;
		padding: 0.9375rem 0;
		color: #7a798a;
		font-weight: normal;
		border-top: 1px solid rgba(206, 206, 211, 0.24);
		@media (max-width: 480px) {
			padding: 1rem;
			font-size: 1rem;
		}
	}
	td {
		border-top: 1px solid rgba(206, 206, 211, 0.24);
		padding: 0.9375rem 0;

		@media (max-width: 480px) {
			padding: 1rem;
			font-size: 1rem;
		}
		&.woocommerce-product-attributes-item__label {
			width: 18.75rem;
			@media (max-width: 480px) {
				width: 10rem;
			}
		}
		p {
			margin: 0;
		}
	}
}
