/***** FONTS *****/
@import "fonts";

/***** GENERAL ELEMENTS *****/
// COLORS

$global-color: #e5002b;
$dop-color: #ce0329;

$font-color: #151515;
$font-color-sub: #000;
$font-color-gray: #767980;
$font-color-light: #919395;

@import "general";
@import "blocks/buttons";
@import "blocks/title";
@import "blocks/content";
@import "blocks/links";


/***** BLOCKS GLOBAL *****/
@import "blocks/header";
@import "blocks/navigation";
@import "blocks/breadcrumbs";
@import "blocks/pagination";
@import "blocks/carousel";
@import "blocks/article";
@import "blocks/product";
@import "blocks/post";
@import "blocks/brand-list";
@import "blocks/form";
@import "blocks/modal";
@import "blocks/socials";
@import "blocks/footer";
@import "blocks/filter";

/***** SECTIONS GLOBAL *****/
@import "sections/sections";
@import "sections/intro";
@import "sections/advantages";
@import "sections/offer";
@import "sections/cars";
@import "sections/line";
@import "sections/solutions";
@import "sections/services";
@import "sections/about";


@import "sections/filter";
@import "sections/brands";

@import "sections/related";
@import "sections/forms";
@import "sections/faq";
@import "sections/error";


/***** COMPONENTS PAGE (Article) *****/
@import "page/blog";
@import "page/single";
@import "page/about";
@import "page/catalog";
@import "page/product-detail";
