.filter {
	position: relative;
	padding: 0.9375rem;
	border-radius: 0.9375rem;
	background: #ffffff;
	display: flex;
	gap: 0.625rem;

	.button {
		padding-block: 0.8125rem;
		padding-inline: 1.5625rem;
	}
	.filter-block {
		@media screen and (max-width: 550px) {
			width: 100%;
			flex: auto;
		}
	}
}

.select-block {
	font-size: 1rem;
	width: 12.5rem;
	// flex: 1;
	&.counter {
		flex: 1;
		flex-basis: 18.75rem;
		width: 18.75rem;
	}
	@media screen and (max-width: 992px) {
		flex: 1;
	}
}

.selection {
}

.select2-container {
	border-radius: 0.625rem;
	color: #767980;
	background: #ffffff;
	border: 1px solid #e0e1e7;
	height: 3rem;
	// padding-top: 0.625rem;
	padding-inline: 0.9375rem;
}

.select2-container--default .select2-selection--single {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	border: 0rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	position: static;
	display: flex;
	justify-content: center;
	align-items: center;

	b {
		position: inherit;
	}
}
