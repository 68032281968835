.section-offer {
}

.offer {
	margin-top: 5rem;
	display: flex;
	flex-wrap: wrap;
	gap: 2.5rem;
	@media (max-width: 992px) {
		margin-top: 3rem;
		gap: 1.5rem;
	}
	.item {
		width: calc(50% - 1.25rem);
		min-height: 13.75rem;
		border-radius: 0.4375rem;
		padding: 2.5rem;
		box-shadow:
			0.375rem 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.2),
			inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.25);
		background-color: rgb(21, 21, 21);
		display: flex;
		align-items: center;
		background-size: cover;
		background-position: 50% 50%;
		@media (max-width: 992px) {
			width: 100%;
		}
		@media (max-width: 480px) {
			padding: 1.5rem;
		}
		.block {
			max-width: 18.75rem;
			color: #fff;
			@media (max-width: 992px) {
				font-size: 1.1rem;
			}
		}
		.title {
			font-family: "Montserrat-ExtraBold";
			font-size: 1.5rem;
			text-transform: uppercase;
			margin-bottom: 0.5rem;
			@media (max-width: 480px) {
				font-size: 1.3rem;
			}
		}
	}
}
