.section-forms {
	margin-top: 3.75rem;
	@media (max-width: 992px) {
		margin-top: 2rem;
	}
}
.forms {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
	.block {
		width: calc(100% - 31.625rem);
		border: 0.25rem solid rgb(236, 236, 236);
		border-radius: 0.5rem;
		background: rgb(255, 255, 255);
		@media (max-width: 992px) {
			width: 100%;
		}
	}
	.block-text {
		width: 30.625rem;
		min-height: 25rem;
		border-radius: 1.25rem;
		background-image: url(../img/contacts.jpg);
		background-size: cover;
		background-position: 50% 50%;
		@media (max-width: 992px) {
			width: 100%;
			min-height: 5rem;
		}
		ul {
			li {
				a {
					text-decoration: none;
					color: $font-color;
					font-size: 1.25rem;
					text-transform: lowercase;
					&:hover {
						color: $dop-color;
					}
				}
			}
		}

		.socials a {
			display: block;
			width: 2rem;
			height: 2rem;
		}
	}

	.forma {
		margin-top: 2rem;
		.button {
			font-size: 1rem;
		}
	}
}
