.section-solutions {
	position: relative;
	margin-bottom: 16rem;
	@media (max-width: 992px) {
		margin-bottom: 8rem;
	}
	&:after {
		content: "";
		position: absolute;
		top: -8rem;
		bottom: -8rem;
		left: 0;
		right: -50%;

		transform: skew(-55deg);
		background: #d7d7d7;
		z-index: 1;
		@media (max-width: 992px) {
			top: -4rem;
			bottom: -4rem;
			transform: skew(-40deg);
		}
	}
}

.archive {
	.section-solutions {
		position: relative;
		margin-bottom: 0;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
		}
	}
}

.steps-box {
	display: flex;
	gap: 1.25rem;
	width: 100%;
	border: 0.25rem solid rgb(236, 236, 236);
	border-radius: 0.5rem;
	background: rgb(255, 255, 255);
	overflow: hidden;
	margin-bottom: 5rem;
	position: relative;
	z-index: 10;
	@media (max-width: 992px) {
		margin-bottom: 3rem;
	}
	.block {
		width: calc(100% - 16.25rem);
		padding: 2.5rem;
		@media (max-width: 992px) {
			width: 100%;
		}
		@media (max-width: 680px) {
			padding: 1.5rem;
		}
	}
	.images {
		width: 16.25rem;
		background-image: url(../img/steps.jpg);
		background-size: cover;
		background-position: 50% 50%;
		@media (max-width: 992px) {
			display: none;
		}
	}
}

.steps {
	display: flex;
	flex-wrap: wrap;
	gap: 0 2rem;
	width: 100%;
	margin-top: 1.5rem;
	.item {
		background: #ffffff;
		width: calc(33.33% - 1.4rem);
		display: flex;
		align-items: center;
		gap: 1.25rem;
		padding: 1.875rem 0;
		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			border-bottom: 0.125rem solid rgb(174, 174, 174);
		}

		@media (max-width: 992px) {
			width: calc(50% - 1rem);
			&:nth-child(4) {
				border-bottom: 0.125rem solid rgb(174, 174, 174);
			}
		}
		@media (max-width: 540px) {
			width: 100%;
			&:nth-child(5) {
				border-bottom: 0.125rem solid rgb(174, 174, 174);
			}
		}
	}

	.num {
		width: 4.0625rem;
		height: 4.0625rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.375rem;
		background-color: $dop-color;
		box-shadow:
			0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2),
			inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.05);
		font-family: "Montserrat-ExtraBold";
		font-size: 2rem;
		color: #ffffff;
	}

	.content {
		width: calc(100% - 5.3125rem);
		display: flex;
		flex-direction: column;
		gap: 0.625rem;
		font-size: 0.875rem;
		@media (max-width: 540px) {
			font-size: 1.1rem;
		}
	}

	.name {
		font-family: "Montserrat-Bold";
	}
}
