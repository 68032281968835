.pagination {
	width: 100%;
	margin: 1.5rem 0;
	.nav-links {
		width: 100%;
		display: flex;
		justify-content: center;
		gap: 0.625rem;
		.prev,
		.next {
			background-size: 1rem;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}
		.prev {
			transform: rotate(180deg);
		}
		.page-numbers {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 0.1875rem;
			font-family: "Montserrat-Medium";
			font-size: 1rem;

			&.current {
				background-color: $global-color;
				color: #fff;
			}
		}
	}
}

.woocommerce-pagination {
	ul {
		display: flex;
		justify-content: center;
		padding: 0;
		list-style: none;
		gap: 0.5rem;
		margin: 2rem 0;
		.page-numbers {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 2.5rem;
			height: 2.5rem;
			padding: 0.5rem;
			border-radius: 0.1875rem;
			font-family: "Montserrat-Medium";
			font-size: 1rem;
			@media (max-width: 680px) {
				width: 1.8rem;
				height: 1.8rem;
			}
			&.current {
				background-color: $global-color;
				color: #fff;
			}
			&.next,
			&.prev {
				font-size: 0;
				&:after {
					content: "";
					width: 0.5rem;
					height: 0.5rem;
					border-top: 0.125rem solid #919395;
					border-right: 0.125rem solid #919395;
					transform: rotate(45deg);
				}
				&:hover {
					&:after {
						border-color: $global-color;
					}
				}
			}
			&.prev {
				&:after {
					transform: rotate(-135deg);
				}
			}
		}
	}
}
