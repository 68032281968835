html {
	font-size: 16px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	@media (max-width: 1600px) {
		font-size: 15px;
	}
	
	@media (max-width: 1440px) {
		font-size: 14px;
	}
	@media (max-width: 1300px) {
		//font-size: 13px;
	}

	@media (max-width: 1140px) {
	font-size: 13px;
	}
	@media (max-width: 1080px) {
		//font-size: 11px;
	}
	@media (max-width: 992px) {
		font-size: 15px;
	}
	@media (max-width: 440px) {
		font-size: 13px;
	}
}
body {
	font-family: "Montserrat-Regular";
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4;
	color: $font-color;
	background-color: #fff;
	margin: 0;
	width: 100%;
	overflow-x: hidden;
	position: relative;
	&.no-scroll {
		position: fixed;
	}
}

main {
	width: 100%;
	position: relative;
	min-height: calc(100vh - 483px);
	padding-top: 8rem;

	@media (max-width: 992px) {
		padding-top: 0;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
}
.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	@media (min-width: 280px) {
		width: 100%;
	}
	@media (max-width: 380px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
	@media (min-width: 1140px) {
		width: 86rem;
	}
}

.md-hidden {
	display: none;
}
.right {
	text-align: right;
}
.center {
	text-align: center;
}
.hidden {
	display: none !important;
}
.red {
	color: $global-color;
}
@media (min-width: 992px) {
	/* Работает в Firefox */
	* {
		scrollbar-width: thin;
		scrollbar-color: $global-color transparent;
	}

	/* Работает в Chrome, Edge и Safari */
	*::-webkit-scrollbar {
		width: 0.3125rem;
		height: 0.1875rem;
	}

	*::-webkit-scrollbar-track {
		background: transparent;
		border-left: 0.0625rem solid #d4d7e7;
	}

	*::-webkit-scrollbar-thumb {
		background-color: $global-color;
		border-radius: 1.25rem;
	}
	*::-webkit-scrollbar-thumb:hover {
		background-color: #434356;
	}
}
input:-internal-autofill-selected {
	appearance: menulist-button;
	background-image: none !important;
	background-color: transparent !important;
	color: fieldtext !important;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	outline: none !important;
}
a:hover,
button:hover {
	transition: 0.3s;
}
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}
audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}
audio:not([controls]) {
	display: none;
	height: 0;
}

// link
a {
	background-color: transparent;
	color: inherit;
	text-decoration: none;
	//text-underline-offset: 0.2em;
	//text-decoration-thickness: 0.0625rem;
}

a:active,
a:hover,
a:focus {
	outline: 0;
	color: $global-color;
	outline: none;
	outline-offset: 0;
	text-decoration: none;
}
a[role="button"] {
	text-decoration: none;
}

// img
figure {
	margin: 0;
}
img {
	border: 0;
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}
svg,
svg:hover,
path,
polygon {
	transition: 0.05s;
}
svg:not(:root) {
	overflow: hidden;
}

//text
h1,
h2,
h3 {
	font-weight: normal;
	margin: 0;
}
b,
strong {
	font-family: "Montserrat-Bold";
}
dfn {
	font-style: italic;
}

small {
	font-size: 80%;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
mark {
	background: none;
	font-weight: 600;
	color: red;
	margin-left: 0.3125rem;
}
hr {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	margin-top: 0.9375rem;
	margin-bottom: 0.9375rem;
	border: 0;
	border-top: 0.0625rem solid #f2f2f2;
}
pre {
	overflow: auto;
}
code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

//form
button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
}
input:focus,
button:focus,
select:focus,
textarea:focus {
	outline: none;
	outline-offset: 0;
}
button {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}
button[disabled],
html input[disabled] {
	cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
input {
	line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
input[type="search"] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
fieldset {
	border: 0.0625rem solid #c0c0c0;
	margin: 0 0.125rem;
	padding: 0.35em 0.625em 0.75em;
}
legend {
	border: 0;
	padding: 0;
}
textarea {
	overflow: auto;
}
optgroup {
	font-weight: bold;
}

//table
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td,
th {
	padding: 0;
}
