.blog {
	main {
		margin-bottom: 4.75rem;
	}
}

.blog-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 2.5rem 1.875rem;
}

.screen-reader-text {
	display: none !important;
}

.blog-nav {
	list-style-type: none;
	padding-left: 0;
	display: flex;
	gap: 0.9375rem 1.5rem;
	flex-wrap: wrap;
	margin-top: 1.25rem;
	margin-bottom: 1.875rem;

	.menu-item {
		
		&.current-menu-item {
			font-family: "Montserrat-Medium";
			color: $global-color;
		}

		&:hover,
		&.current-menu-item {
			color: $dop-color;
			transition: all 0.3s;
		}

		a {
			display: block;
		}
	}
}
