.sidebar .filter {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	gap: 0.875rem 1rem;
	padding: 0;
	@media (max-width: 992px) {
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100vh;
		overflow-y: auto;
		border-radius: 0;
		padding: 3rem 1rem;
	}

	.widget-filter {
		position: relative;
		z-index: 10;
		width: calc(17.37% - 0.5rem);
		order: 11;
		@media (max-width: 992px) {
			width: 100%;
		}

		&.disabled {
			opacity: 0.5;
			cursor: default;
			.filter-head {
				cursor: default;
			}
		}
		//border-bottom: 2px solid rgb(174, 174, 174);
		&.widget-filter-conditions,
		&.widget-filter-region {
			width: calc(23% - 0.5rem);
			order: 1;
			@media (max-width: 992px) {
				width: 100%;
			}
		}
		&.widget-filter-price {
			width: calc(30% - 0.5rem);
			order: 2;
			margin-left: auto;
			@media (max-width: 992px) {
				width: 100%;
			}
		}
		&.widget-filter-brand,
		&.widget-filter-model {
			width: calc(23% - 0.5rem);
			order: 10;
			@media (max-width: 992px) {
				width: 100%;
			}
		}

		&.widget-filter-pover,
		&.widget-filter-km,
		&.widget-filter-year,
		&.widget-filter-volume {
			width: calc(15.68% - 0.5rem);
			order: 12;
			@media (max-width: 992px) {
				width: 100%;
			}
		}

		&.widget-filter-pa_wheelformula,
		&.widget-filter-pa_color {
			order: 13;
		}
		@media (max-width: 992px) {
			width: 100%;
		}
		&:nth-child(n + 8) {
			z-index: 5;
		}
		&:nth-child(n + 11) {
			z-index: 2;
		}
	}

	.filter-head {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		height: 3.5rem;
		padding: 0.4rem 1.75rem 0.4rem 0.75rem;
		background: #f1f3f7;
		border: 1px solid #fff;
		gap: 0.25rem 1rem;
		position: relative;
		cursor: pointer;
		transition: 0.3s;
		.title {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			//font-family: "Montserrat-SemiBold";
			font-size: 0.875rem;
			color: #151515;
			@media (max-width: 992px) {
				font-size: 1.1rem;
			}
		}
		.select {
			font-size: 0.75rem;
			color: $dop-color;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			@media (max-width: 992px) {
				font-size: 1rem;
			}
		}
		&:after {
			content: "";
			display: flex;
			width: 0.625rem;
			height: 0.625rem;
			margin-left: auto;
			background-image: url(../img/svg/path-right.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			transition: 0.4s;
			transform: rotate(90deg);
			opacity: 0.8;
			position: absolute;
			top: calc(50% - 0.3125rem);
			right: 0.75rem;
		}
	}
	.filter-body {
		width: 100%;
		min-width: 15rem;
		margin-top: 0.25rem;
		padding: 0;
		position: absolute;
		top: 100%;
		left: 0;
		border: 1px solid #e0e1e7;
		border-radius: 0.5rem;
		background: #fbfbfb;
		display: none;
		//overflow: hidden;
		&.active {
			display: block;
		}
		.reset {
			display: none;
			font-size: 0.75rem;
			color: $dop-color;
			padding: 1rem 0.75rem 0;
			cursor: pointer;
			@media (max-width: 992px) {
				font-size: 1rem;
			}
			&.active {
				display: flex;
				gap: 0.25rem;
				align-items: center;
				.close {
					width: 0.75rem;
					height: 0.75rem;
					cursor: pointer;
					position: relative;
				}
				.close:before,
				.close:after {
					content: "";
					display: block;
					width: 0.75rem;
					height: 0.125rem;
					background: $dop-color;
					position: absolute;
					transition: 0.6s;
					top: 0.3rem;
				}
				.close:before {
					transform: rotate(45deg);
				}
				.close:after {
					transform: rotate(-45deg);
				}
			}
			&:hover {
				color: #000;
				.close {
					&:before,
					&:after {
						background: #000;
					}
				}
			}
		}

		@media (max-width: 992px) {
			position: static;
			width: 100%;
			margin: 0;
			border: none;
			border-radius: 0;
			padding: 0;
			background: #fff;
		}

		> ul {
			max-height: 28rem;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: #f0f2f5 transparent;
			@media (max-width: 992px) {
				max-height: 18.125rem;
			}
			/* Работает в Chrome, Edge и Safari */
			&::-webkit-scrollbar {
				width: 0.3125rem;
				height: 0.1875rem;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
				border-left: 1px solid #d4d7e7;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #f0f2f5;
				border-radius: 1.25rem;
			}
			&::-webkit-scrollbar-thumb:hover {
				background-color: #434356;
			}
		}
		ul {
			list-style: none;
			padding: 1rem 0.75rem;
			margin: 0;
			li {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				gap: 0 0.5rem;
				font-family: "Montserrat-Regular";
				font-size: 1rem;
				margin: 0 !important;
				@media (max-width: 992px) {
					padding: 0;
					font-size: 1.1rem;
				}
				&.item-more {
					border-top: 1px solid $global-color !important;
					font-size: 0.75rem;
					padding-top: 0.5rem;
					//text-align: center;
					//justify-content: center;
					cursor: pointer;
					&:hover {
						color: $dop-color;
					}
				}
				&.tax_hide {
					display: none !important;
				}
				&:last-child {
					border: none;
				}
				.togg {
					display: flex;
					width: 1.5rem;
					height: 1.5rem;
					margin-left: auto;
					background-image: url(../img/icons/arrow.svg);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					transition: 0.3s;
					cursor: pointer;
					&.active {
						transform: rotate(180deg);
						transition: 0.3s;
					}
				}
				.item {
					display: flex;
					width: 100%;
					padding: 0.5rem 0;
				}
				.item > input {
					position: absolute;
					z-index: -1;
					opacity: 0;
				}
				.item > span {
					width: 100%;
					display: flex !important;
					align-items: center;
					gap: 0.5rem;
					user-select: none;
					font-size: 0.75rem;
					cursor: pointer;
					@media (max-width: 992px) {
						font-size: 1.1rem;
					}

					.count {
						font-size: 0.65rem;
						margin-left: auto;
					}
				}
				.item > span::before {
					content: "";
					display: inline-block;
					width: 1rem;
					height: 1rem;
					flex-shrink: 0;
					flex-grow: 0;
					border: 0.125rem solid #aeb6c5;
					border-radius: 0.25rem;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 70% 70%;
					margin-top: -0.125rem;
					@media (max-width: 992px) {
						width: 1.2rem;
						height: 1.2rem;
					}
				}
				.item > input:not(:disabled):not(:checked) + span:hover {
					color: #031b4c;
				}
				.item > input:not(:disabled):not(:checked) + span:hover::before {
					border-color: #5e6d8d;
				}

				.item > input:checked + span {
					color: #111;
				}
				.item > input:checked + span::before {
					border-color: $global-color;
					background-color: $global-color;
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11 1.0625L4.125 7.9375L1 4.8125' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
				}
			}
		}
	}

	.filter-body-buttons {
		width: 100%;
		display: flex;

		.item-button {
			display: flex;
			width: 100%;
		}
		.item-button > input {
			position: absolute;
			z-index: -1;
			opacity: 0;
		}

		.item-button > span {
			width: 100%;
			height: 2.5rem;
			display: flex !important;
			align-items: center;
			justify-content: center;
			padding: 0.5rem 0.75rem;
			gap: 0.5rem;
			user-select: none;
			font-size: 0.875rem;
			background: #f1f3f7;
			border: 1px solid #fff;
			color: #000;
			cursor: pointer;
			white-space: nowrap;
		}

		.item-button > input:not(:disabled):not(:checked) + span:hover {
			color: #000;
		}

		.item-button > input:checked + span {
			color: #fff;
			background: $dop-color;
		}
	}

	.filter-body-range {
		width: 100%;
		display: flex;
		align-items: flex-end;
		gap: 1.5rem;
		white-space: nowrap;
		@media (max-width: 992px) {
			flex-direction: column;
			gap: 1rem;
		}
		.rage-block {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			background: #f1f3f7;
			.lb {
				width: 100%;
				font-size: 0.85rem;
				color: #151515;
				background: #f1f3f7;
				border: 1px solid #fff;
				padding: 0.25rem 0.75rem;
				@media (max-width: 992px) {
					font-size: 1.1rem;
				}
			}
			.widget-filter {
				width: 50%;
				.filter-head {
					height: 2rem;
					@media (max-width: 992px) {
						height: 3.5rem;
					}
				}
				.filter-body {
					width: 100%;
					min-width: 5rem;
				}
			}
			input {
				width: 50%;
				height: 2rem;
				padding: 0.5rem 0.75rem;
				font-size: 0.875rem;
				background: #f1f3f7;
				border: 1px solid #fff;
				color: $dop-color;
				@media (max-width: 992px) {
					height: 3.5rem;
					font-size: 1.1rem;
				}
			}
		}
		.item-check {
			display: flex;
			
			padding: 0.5rem 0;
		}
		.item-check > input {
			position: absolute;
			z-index: -1;
			opacity: 0;
		}
		.item-check > span {
			width: 100%;
			display: flex !important;
			align-items: center;
			gap: 0.5rem;
			user-select: none;
			font-size: 1rem;
			cursor: pointer;
			@media (max-width: 992px) {
				font-size: 1.1rem;
			}
		}
		.item-check > span::before {
			content: "";
			display: inline-block;
			width: 1rem;
			height: 1rem;
			flex-shrink: 0;
			flex-grow: 0;
			border: 0.125rem solid #aeb6c5;
			border-radius: 0.25rem;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 70% 70%;
			margin-top: -0.125rem;
			@media (max-width: 992px) {
				width: 1.2rem;
				height: 1.2rem;
			}
		}
		.item-check > input:not(:disabled):not(:checked) + span:hover {
			color: #031b4c;
		}
		.item-check > input:not(:disabled):not(:checked) + span:hover::before {
			border-color: #5e6d8d;
		}

		.item-check > input:checked + span {
			color: #111;
		}
		.item-check > input:checked + span::before {
			border-color: $global-color;
			background-color: $global-color;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11 1.0625L4.125 7.9375L1 4.8125' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
		}
	}

	.filter-buttons {
		width: 100%;
		order: 100;
		display: flex;
		align-items: center;
		gap: 1.5rem;
		justify-content: flex-end;
		@media (max-width: 992px) {
			flex-direction: column;
			gap: 1rem;
		}
		.filter-count {
			@media (max-width: 992px) {
				font-size: 1.3rem;
				order: 1;
			}
		}
		.filter-submit {
			@media (max-width: 992px) {
				width: 100%;
				order: 2;
			}
			&.disabled {
				opacity: 0.5;
				cursor: default;
			}
		}
		.filter-reset {
			margin-right: auto;
			border: none;
			background: transparent;
			font-family: "Montserrat-Medium";
			font-size: 0.875rem;
			color: $dop-color;

			display: flex;
			gap: 0.5rem;
			align-items: center;
			@media (max-width: 992px) {
				width: 100%;
				order: 3;
				height: 3rem;
				justify-content: center;
				text-align: center;
				font-family: "Montserrat-Bold";
				font-size: 1rem;
				line-height: 1;
				text-transform: uppercase;
				border-radius: 0.25rem;
				box-shadow:
					0.375rem 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1),
					inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.1);
				border: 1px solid transparent;
				padding: 0.75rem 1.5rem;
				gap: 0.625rem;
				position: relative;
				&:hover,
				&:active,
				&:focus {
					box-shadow:
						0.1875rem 0.1875rem 0.3125rem 0 rgba(0, 0, 0, 0.2),
						inset -0.25rem -0.25rem 0.5625rem 0 rgba(0, 0, 0, 0.03);
				}
			}
			.close {
				width: 1rem;
				height: 1rem;
				cursor: pointer;
				position: relative;
			}
			.close:before,
			.close:after {
				content: "";
				display: block;
				width: 1rem;
				height: 0.125rem;
				background: $dop-color;
				position: absolute;
				transition: 0.6s;
				top: 0.4rem;
			}
			.close:before {
				transform: rotate(45deg);
			}
			.close:after {
				transform: rotate(-45deg);
			}
			&:hover {
				color: #000;
			}
		}
	}
}
