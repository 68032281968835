@font-face {
								font-family: "Montserrat-Black";
								font-display: swap;
								src: url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff");
								font-weight: 900;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-Bold";
								font-display: swap;
								src: url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff");
								font-weight: 700;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-ExtraLight";
								font-display: swap;
								src: url("../fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLight.woff") format("woff");
								font-weight: 200;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-ExtraBold";
								font-display: swap;
								src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff");
								font-weight: 800;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-Light";
								font-display: swap;
								src: url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff");
								font-weight: 300;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-Regular";
								font-display: swap;
								src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
								font-weight: 400;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-SemiBold";
								font-display: swap;
								src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
								font-weight: 600;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-Medium";
								font-display: swap;
								src: url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff");
								font-weight: 500;
								font-style: normal;
								}
@font-face {
								font-family: "Montserrat-Thin";
								font-display: swap;
								src: url("../fonts/Montserrat-Thin.woff2") format("woff2"), url("../fonts/Montserrat-Thin.woff") format("woff");
								font-weight: 100;
								font-style: normal;
								}
